import { createStore, createEffect } from "effector";
import { IConvoyProduct, IQueryConvoyProducts } from "./types";
import axios from "axios";


export const $convoyproducts = createStore<IConvoyProduct[]>([])
export const queryConvoyProducts = createEffect<IQueryConvoyProducts, IConvoyProduct[]>();

$convoyproducts.on(queryConvoyProducts.doneData, (_, val) => val);

queryConvoyProducts.use(async ({setLoading, setFailMsg}) => {

  var empty_pack=""

  await axios.post('https://kras-abs.ru/adminka/release/escort/list', {}).then(res => {
    empty_pack= res.data.result})
  

//@ts-ignore
  return empty_pack.reverse() as IConvoyProduct[]
//@ts-ignore
  // return [
  //   {id: 1, name: "Ежемесячные обновления", 
  //     satisfiedClients: [],
  //     logo_path: "",
  //     description: "Персональный программист обновить вашу программу 1С........................................."},
  //   {id: 2, name: "Линия консультации 24/7",
  //     satisfiedClients: [/*IconClient2, IconClient4*/ ],
  //     logo_path: "",
  //     description: "Наши консультанты помогу решить ваши вопросы по работе..............."},
    
  //     // {id: 3, name: "123", 
  //     // logo_path: "",
  //     // description: "описание", },
  //     {id: 4, name: "456", 
  //     logo_path: "",
  //     description: "описание2"},
  //     {id: 5, name: "789", 
  //     logo_path: "",
  //     description: "описание3"}
     
  // ] as IConvoyProduct[]
})
