
import axios from 'axios';


export async function getData(){
var empty_pack=""

  const responce = await axios.post('https://kras-abs.ru/adminka/release/client/byCompanyId', {company_id: 1})
    empty_pack= responce.data.clients.reverse()

    return  empty_pack
    
}